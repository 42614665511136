<template>
  <v-app-bar app key="app_bar" clipped-left color="primary">
    <v-btn
      class="mr-4"
      @click="$emit('hideMobileNavigation')"
      v-if="isMobile"
      icon
    >
      <v-icon style="color: white">
        {{ hideMobileNavigation ? "fas fa-times" : "fa fa-bars" }}
      </v-icon>
    </v-btn>
    <div style="position: relative; width: 300px">
      <img width="130" src="@/assets/logo-argos.png" />
      <span
        style="
          color: black;
          font-size: 14px;
          position: absolute;
          left: 25px;
          bottom: 5px;
        "
        class="font-weight-bold d-none d-md-inline d-sm-inline"
        >PLAN DE LEALTAD CEMENTOS ARGOS</span
      >
    </div>

    <v-spacer />

    <v-text-field
      v-if="$store.state.visibleSearch"
      v-model="search"
      solo-inverted
      clearable
      rounded
      flat
      style="background-color: #fff; border-radius: 10px"
      hide-details
      :label="`Buscar ${$store.state.searchTerm}`"
      prepend-inner-icon="fa-search "
      class="d-none d-md-flex"
    />

    <!-- <v-text-field
      v-if="$store.state.visibleSearch"
      v-model="search"
      flat
      hide-details
      :label="`Buscar ${$store.state.searchTerm}`"
      class="d-block d-md-none d-xs-none"
    /> -->
    <v-btn
      icon
      color="#fff"
      absolute
      right
      class="d-block d-md-none d-xs-none"
      @click="searchMobile = !searchMobile"
    >
      <v-icon>{{ searchMobile ? "fas fa-times" : "fa-search" }}</v-icon>
    </v-btn>

    <v-spacer v-if="!isMobile" />

    <div
      class="mr-3"
      v-if="$store.state.user.type === 'sudo' && !isMobile"
      @click="hideSideBar"
    >
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn fab x-small v-bind="attrs" v-on="on">
            <i :class="hideMenuIcon"></i>
          </v-btn>
        </template>
        <span>{{ hideMenuToolTip }}</span>
      </v-tooltip>
    </div>

    <span
      v-if="!isMobile"
      class="headline font-weight-bold titleBasa ml-0 mr-5 black--text d-none d-md-block"
      style="position: relative; top: -6px"
    >
      Plataforma
      <span
        style="
          position: absolute;
          bottom: -18px;
          left: 0;
          font-size: 14px;
          font-weight: normal;
          color: black;
          width: 100%;
          text-align: right;
        "
      >
        Administrativa
      </span>
    </span>
    <transition name="slide-fade">
      <div class="mobile-search" v-if="searchMobile">
        <v-text-field
          v-if="$store.state.visibleSearch"
          v-model="search"
          solo-inverted
          clearable
          rounded
          flat
          color="#fff"
          hide-details
          :label="`Buscar ${$store.state.searchTerm}`"
          prepend-inner-icon="fa-search"
          style="background-color: #fff; border-radius: 10px"
          class="search-mobile d-block d-md-none d-xs-none"
        />
      </div>
    </transition>
  </v-app-bar>
</template>

<script>
import LogoOcho from "@/components/LogoOcho";

export default {
  props: ["hideMobileNavigation", "isMobile"],
  name: "app-bar-c",
  components: {
    LogoOcho,
  },

  data() {
    return {
      searchMobile: false,
      hideMenuToolTip: "Esconder menu",
      hideMenuIcon: "fas fa-eye-slash",
    };
  },

  computed: {
    search: {
      get: function () {
        return this.$store.state.search;
      },
      set: function (newValue) {
        this.$store.commit("setSearch", newValue);
      },
    },

    environment() {
      return process.env.NODE_ENV;
    },
  },

  methods: {
    goToHome() {
      this.$router.push({ path: "/" });
    },
    hideSideBar() {
      const navBar = document.querySelector(".v-navigation-drawer");
      const main = document.querySelector("main");

      if (navBar.style.width == "280px") {
        navBar.style.width = "0px";
        main.style.padding = "64px 0px 45px 0px";
        this.hideMenuIcon = "fas fa-eye";
        this.hideMenuToolTip = "Mostrar menu";
      } else {
        navBar.style.width = "280px";
        main.style.padding = "64px 0px 45px 280px";
        this.hideMenuIcon = "fas fa-eye-slash";
        this.hideMenuToolTip = "Esconder menu";
      }
    },

    // showN
  },
};
</script>

<style scoped>
.fa-search {
  color: #d5d5d5;
}
.mobile-search {
  width: 100%;
  position: absolute;
  height: auto;
  top: 55px;
  left: 0px;
  padding: 10px 10px;
  background-color: #c1d72f;
  z-index: 1;
}
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: 0.3s ease-in-out;
}

.slide-fade-enter-from {
  /* transform: translateY(-20px); */
  opacity: 0;
}
.slide-fade-leave-to {
  /* transform: translateY(-20px); */
  opacity: 0;
}
</style>