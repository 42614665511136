<template>
  <v-list expand ref="menu" color="#0F0613">
    <v-text-field
      v-model="searchMenu"
      hide-details
      class="pa-3 mb-3"
      rounded
      filled
      clearable
      placeholder="Buscar"
    />

    <div v-for="(item, id) of filteredMenu" :key="id">
      <!-- MENU SIN HIJOS -->
      <v-list-item
        color="#0F0613"
        v-on:click="$emit('handleClick', item)"
        :target="item.target"
        :to="item.link"
        v-if="!item.children && item.roles.includes(user.type)"
      >
        <v-list-item-icon>
          <v-icon v-text="item.icon"></v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="item.title"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- MENU CON HIJOS-- SE AGRUPA -->
      <!-- {{helpRequests}} -->
      <v-list-group
        :group="item.group"
        v-if="item.children && item.roles.includes(user.type)"
        :key="item.title"
      >
        <template v-slot:activator>
          <v-list-item-icon>
            <v-icon
              :class="[
                {
                  'new-help-request':
                    item.title === 'Ventas' &&
                    (helpRequests.length || scheduledOrders.length),
                },
              ]"
             
            >{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-title
            :class="[
              {
                'new-help-request':
                  item.title === 'Ventas' &&
                  (helpRequests.length || scheduledOrders.length),
              },
            ]"
            >{{ item.title }}
          </v-list-item-title>
        </template>

        <div v-for="child in item.children" :key="child.title">
          <div v-if="child.type == 'divide'">
            <hr style="border: 0.5px solid #878080" />
          </div>

          <div v-if="!child.type && child.roles.includes(user.type)">
            <v-list-item
              v-on:click="$emit('handleClick', child)"
              :target="child.target"
              :to="child.link"
              class="ml-7"
              v-if="!child.children"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ child.title }}
                  <v-badge
                    v-if="
                      helpRequests.length &&
                      child.title == 'Peticiones de Ayuda'
                    "
                    class="ml-2"
                    color="primary"
                    :content="helpRequests.length"
                  />

                  <v-badge
                    v-if="
                      scheduledOrders &&
                      scheduledOrders.length &&
                      child.title == 'Órdenes programadas'
                    "
                    class="ml-1"
                    color="primary"
                    :content="scheduledOrders.length"
                  />
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon v-text="child.icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>

            <v-list-group
              :group="child.group"
              :key="child.title"
              class="ml-3"
              v-else
              v-model="child.active"
              sub-group
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{ child.title }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon class="font-weight-bold">
                    {{
                      child.active ? "fal fa-chevron-up" : "fal fa-chevron-down"
                    }}</v-icon
                  >
                </v-list-item-icon>
              </template>

              <div v-for="(e, i) in child.children" :key="i">
                <v-list-item
                  v-if="e.roles.includes(user.type)"
                  v-on:click="$emit('handleClick', e)"
                  :target="e.target"
                  :to="e.link"
                  class="ml-3"
                  link
                >
                  <v-list-item-title v-text="e.title"></v-list-item-title>
                </v-list-item>
              </div>
            </v-list-group>
          </div>
        </div>
      </v-list-group>
    </div>

    <!-- Muestra el comercio seleccionado -->
    <v-list-group
      class="business-active"
      group="/business/"
      v-if="selectedBusiness"
    >
      <template v-slot:activator>
        <v-list-item-avatar id="businessMenu" v-if="selectedBusiness.logo">
          <v-img
            :src="selectedBusiness.logo.original || ochoLogoSrc"
            max-height="80%"
            max-width="80%"
          >
          </v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ selectedBusiness.shortName }}
          </v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item
        v-for="subItem in getBusinessMenu"
        :key="subItem.index"
        :to="`${subItem.link}`"
        style="padding-left: 70px"
      >
        <v-list-item-content>
          <v-list-item-title v-text="subItem.title"> </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-show="selectedBusiness.isWarehouse"
        v-for="subItem in getWarehouseBusinesses"
        :key="subItem.index"
        :to="`${subItem.link}`"
        style="padding-left: 70px"
      >
        <v-list-item-content>
          <v-list-item-title v-text="subItem.title"> </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-group>

    <!-- MUESTRA EL COMERCIO CARGO SELECCIONADO -->
    <v-list-group
      :group="selectedCorporative.type == 'coupons' ? '/coupons/' : '/cargo/'"
      class="business-active"
      v-if="selectedCorporative && user.type != 'corporateCoupons'"
    >
      <template v-slot:activator>
        <v-list-item-avatar id="cargoMenu">
          <v-img
            v-if="selectedCorporative.logo"
            :src="selectedCorporative.logo.original"
            :lazy-src="selectedCorporative.logo.loading"
            max-height="80%"
            max-width="80%"
          />
          <v-img v-else :src="ochoLogoSrc" max-height="80%" max-width="80%" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title
            v-text="selectedCorporative.shortName"
          ></v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item
        v-for="subItem in selectedCorporative.type == 'coupons'
          ? corporativeCoupons
          : corporativeMenu"
        :key="subItem.index"
        :to="`${subItem.link}`"
        style="padding-left: 70px"
      >
        <v-list-item-content>
          <v-list-item-title v-text="subItem.title"> </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-show="selectedCorporative.isWarehouse"
        v-for="subItem in WarehouseBusinesses"
        :key="subItem.index"
        :to="`${subItem.link}`"
        style="padding-left: 70px"
      >
        <v-list-item-content>
          <v-list-item-title v-text="subItem.title"> </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-group>
  </v-list>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "menu-d",
  props: ["helpRequests", "scheduledOrders"],
  data() {
    return {
      selectedItem: "",
      searchMenu: "",
      ochoLogoSrc: require("@/assets/logo-argos.png"),
      // "https://firebasestorage.googleapis.com/v0/b/el-ocho-data/o/product-default.jpg?alt=media&token=2936fc1f-b1de-49f8-81dd-8ad132ef52df",
      businessMenu: [
        // {
        //   index: 8,
        //   title: "Ordenes",
        //   link: `/business/orders`,
        //   disabled: false,
        //   roles: ["sudo", "admin", "EFCM", "AC", "ACC", "AF"],
        // },
        {
          index: 0,
          title: "General",
          link: `/business/general`,
          disabled: false,
          roles: ["sudo", "businessOwner", "admin", "EFCM", "AC", "ACC", "AF"],
        },
        {
          index: 1,
          title: "API",
          link: `/business/api`,
          roles: ["sudo", "admin"],
          disabled: false,
        },
        {
          index: 2,
          title: "Mapa",
          link: `/business/map`,
          roles: ["sudo", "businessOwner", "admin", "AF", "EFCM", "AC"],
          disabled: false,
        },
        // {
        //   index: 3,
        //   title: "Horarios",
        //   link: `/business/schedule`,
        //   disabled: false,
        //   roles: ["sudo", "businessOwner", "admin", "AF", "EFCM", "AC"],
        // },
        // {
        //   index: 4,
        //   title: "Categorías",
        //   link: `/business/sections`,
        //   disabled: false,
        //   roles: ["sudo", "businessOwner", "admin", "AF", "EFCM", "AC"],
        // },
        // {
        //   index: 5,
        //   title: "Complementos de productos",
        //   link: `/business/addons`,
        //   disabled: false,
        //   roles: ["sudo", "businessOwner", "admin", "AF", "EFCM", "AC"],
        // },
        // {
        //   index: 6,
        //   title: "Productos",
        //   link: `/business/products`,
        //   disabled: false,
        //   roles: ["sudo", "businessOwner", "admin", "AF", "EFCM", "AC"],
        // },
        // {
        //   index: 7,
        //   title: "Descuentos",
        //   link: `/business/discounts`,
        //   disabled: false,
        //   roles: ["sudo", "businessOwner", "admin", "EFCM", "AC"],
        // },
      ],

      corporativeMenu: [
        {
          index: 11,
          title: "General",
          link: `/cargo/general`,
          disabled: false,
        },
        { index: 12, title: "Mapa", link: `/cargo/map`, disabled: false },
        {
          index: 13,
          title: "Puntos permitidos",
          link: `/cargo/points`,
          disabled: false,
        },
        {
          index: 14,
          title: "Usuario Maestro",
          link: `/cargo/user`,
          disabled: false,
        },
        {
          index: 15,
          title: "Contratos",
          link: `/cargo/contracts`,
          disabled: false,
        },
        {
          index: 16,
          title: "Integraciones (API)",
          link: `/cargo/api`,
          disabled: false,
        },

        // {
        //   index: 17,
        //   title: "Webhooks",
        //   link: `/cargo/webhook`,
        //   disabled: false,
        // },
      ],
      WarehouseBusinesses: [
        {
          index: 9,
          title: "Lista de precios",
          link: `/business/priceLists`,
          disabled: false,
          roles: ["sudo", "admin", "businessOwner", "EFCM", "AC"],
        },
        {
          index: 10,
          title: "Clientes preferenciales",
          link: `/business/customers`,
          disabled: false,
          roles: ["sudo", "admin", "businessOwner", "EFCM", "AC"],
        },
      ],

      corporativeCoupons: [
        {
          index: 20,
          title: "General",
          link: `/coupons/general`,
          disabled: false,
        },
        {
          index: 21,
          title: "Montos permitidos",
          link: `/coupons/amounts`,
          disabled: false,
        },
        {
          index: 22,
          title: "Beneficiados",
          link: `/coupons/users`,
          disabled: false,
        },
        {
          index: 23,
          title: "Usuario maestro",
          link: `/coupons/master`,
          disabled: false,
        },
        {
          index: 24,
          title: "Cupones",
          link: `/coupons/list`,
          disabled: false,
        },
      ],
    };
  },

  methods: {
    ...mapActions(["addMenu"]),
  },

  computed: {
    ...mapState(["selectedBusiness", "selectedCorporative", "menu", "user"]),

    getBusinessMenu() {
      return this.businessMenu.filter(
        (i) => i.roles && i.roles.includes(this.user.type)
      );
    },

    getWarehouseBusinesses() {
      return this.WarehouseBusinesses.filter(
        (i) => i.roles && i.roles.includes(this.user.type)
      );
    },

    filteredMenu() {
      if (!this.searchMenu) {
        return this.menu;
      }

      let menuCopy = [];
      let tempMenu = Object.assign([], this.menu);

      tempMenu.forEach((menu) => {
        //SEGUNDO NIVEL

        if (menu.children && menu.children.length) {
          menu.children.forEach((child) => {
            //TERCER NIVEL
            if (child.children && child.children.length) {
              menuCopy = [...menuCopy, ...child.children];
            } else {
              menuCopy.push(child);
            }
          });
        } else {
          menuCopy.push(menu);
        }
      });

      return menuCopy.filter((e) =>
        e.title
          .toUpperCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            this.searchMenu
              .toUpperCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          )
      );
    },
  },
};
</script>


<style scoped>
.v-list-item__title {
  font-size: 13px !important;
}

.theme--dark.v-list {
  background: #0f0613 !important;
}

.v-list-item--active {
  color: #ffffff !important;
}

.v-list-item__title {
  font-size: 13px !important;
}
.v-list-group--active .v-list-group__items .v-list-item:hover {
  color: #ffffff !important;
  background: #36283d !important;
  border-radius: 10px 0px 0px 15px;
}
</style>