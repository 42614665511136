<template>
  <div>
    <div class="bg" v-if="bgpic"></div>
    <kinesis-container class="kinetic" v-if="bgpic && !mobile">
      <kinesis-element :strength="5">
        <img
          style="position: absolute; height: 100vh; left: -50vh; top: 0"
          src="~@/assets/pies.png"
          alt="background image"
          srcset
        />
      </kinesis-element>

      <kinesis-element :strength="10">
        <img
          style="position: absolute; height: 100vh; left: -50vh; top: 0"
          src="~@/assets/Ooverlay.png"
          alt="background image"
          srcset
        />
      </kinesis-element>

      <kinesis-element :strength="5">
        <img
          style="position: absolute; height: 100vh; right: -50vh; top: 0"
          src="~@/assets/pies.png"
          alt="background image"
          srcset
        />
      </kinesis-element>

      <kinesis-element :strength="10">
        <img
          style="position: absolute; height: 100vh; right: -50vh; top: 0"
          src="~@/assets/Ooverlay.png"
          alt="background image"
          srcset
        />
      </kinesis-element>
    </kinesis-container>
  </div>
</template>

<script>
import { KinesisContainer, KinesisElement } from "vue-kinesis";

export default {
  name: "Background",
  components: {
    "kinesis-container": KinesisContainer,
    "kinesis-element": KinesisElement,
  },
  props: {
    mobile: {
      type: Boolean,
      default: false,
    },
    bgpic: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // moved as props JoseV.
      // bgpic: true,
      // Never called JoseV.
      // prevHeight: 0
    };
  },
  methods: {
    // Never called JoseV.
    // beforeLeave(element) {
    //   //   this.prevHeight = getComputedStyle(element).height;
    // }
  },
};
</script>

<style lang="scss">
.kinetic {
  position: absolute;
  height: 100vh;
  width: 100vw;
}

.bg {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  width: 100vw;
  height: 100vh;

  background-size: cover;
  background-position: center;
  background-color: #0f0613;

  // &::after{
  //   content: '';
  //   position: fixed;
  //   left: 0;
  //   top: 0;
  //   width: 100%;
  //   height: 100%;
  //   background: linear-gradient(to bottom, rgba(0,0,0,0.75) 0%,rgba(0,0,0,0.4) 50%,rgba(0,0,0,0.85) 100%);
  //   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#a6000000',GradientType=0 );
  // }
}
</style>
