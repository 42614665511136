import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions";
import "firebase/compat/app-check";

let env = process.env.NODE_ENV;

var prodConfig = {
  apiKey: "AIzaSyDDbO08PAGcwt3dHBqP9go736F8_uXnQ74",
  authDomain: "wl-argos.firebaseapp.com",
  projectId: "wl-argos",
  storageBucket: "wl-argos.appspot.com",
  messagingSenderId: "853150181858",
  appId: "1:853150181858:web:b3efac9685d759e671b1bb",
  measurementId: "G-KFZSY4801Z",
};

const devConfig = {
  apiKey: "AIzaSyA9DzJiZPQWatZcKdlqGO1K7lLLjUPWhrw",
  authDomain: "wl-argos-dev.firebaseapp.com",
  projectId: "wl-argos-dev",
  storageBucket: "wl-argos-dev.appspot.com",
  messagingSenderId: "134912962469",
  appId: "1:134912962469:web:b11a1c4fe258fff69fc24c"
};

firebase.initializeApp(env == "production" ? prodConfig : devConfig);

firebase.auth().languageCode = "es";

if (window.origin.includes("localhost")) {
  // self.FIREBASE_APPCHECK_DEBUG_TOKEN =
  //   process.env.VUE_APP_FIREBASE_APPCHECK_DEBUG_TOKEN;
  // firebase.functions().useEmulator("localhost", "5001");
}

// if (process.env.VUE_APP_APP_CHECK) {
//   const appCheck = firebase.appCheck();
//   appCheck.activate(process.env.VUE_APP_APP_CHECK, true);
// }

export const db = firebase.firestore();
export const fb = firebase;
