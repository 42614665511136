/* eslint-disable no-console */
<template>
  <v-app class="my-app">
    <Snackbar style="z-index: 1000" class="Snack" />
    <app-bar-c
      v-if="appFrame"
      :isMobile="isMobile"
      :hideMobileNavigation="hideMobileNavigation"
      @hideMobileNavigation="hideMenu"
    />

    <v-navigation-drawer
      v-model="drawer"
      style="z-index: 90"
      :mini-variant.sync="mini"
      :app="isMobile ? false : true"
      :absolute="isMobile"
      permanent
      width="280px"
      dark
      :class="{ 'nav-drawer-mobile': isMobile }"
      :color="$vuetify.theme.dark ? '#0F0613' : '#0F0613'"
      key="app_nav"
      :clipped="isMobile ? false : true"
      v-if="navBar && hideMobileNavigation"
    >
      <v-list rounded dense style="background: transparent !important">
        <v-list-item two-line class="pa-0 ma-0">
          <v-list-item-avatar color="primary">
            <v-img class="ma-5" src="~@/assets/user.svg"></v-img>
          </v-list-item-avatar>

          <v-list-item-content class="mt-3">
            <v-list-item-title class="subtitle-1 white--text font-weight-bold">
              {{ user.name ? user.name : "Usuario" }}
              <v-icon
                @click="$router.push({ path: '/profile' })"
                class="mr-3 mb-3"
                style="cursor: pointer; float: right"
                color="#ffffff"
              >
                fas fa-edit
              </v-icon>
            </v-list-item-title>

            <v-list-item-subtitle class="primary--text">
              {{ user.type | filterRoleName }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <menu-render
        :scheduledOrders="scheduledOrders"
        :helpRequests="helpRequests"
        @handleClick="handleFunction"
      />

      <template v-slot:append>
        <div class="pa-2 pt-1" style="background-color: rgba(255, 255, 255, 0)">
          <v-btn
            @click="logOut"
            color="primary"
            style="padding: 14px !important"
            rounded
            block
            large
            :elevation="0"
          >
            <v-icon>fa-sign-out-alt</v-icon>
            <span v-if="!mini" class="ml-2 black--text font-weight-bold">
              Cerrar Sesión
            </span>
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <!-- </v-scale-transition> -->

    <v-main>
      <background key="app_bg" v-if="!appFrame" :mobile="isMobile" />
      <transition-page>
        <router-view v-if="!loading" />
        <v-container v-else class="fill-height">
          <v-row align-content="center" justify="center" class="pa-0 ma-0">
            <v-col cols="12" xs="12" sm="6" md="4" lg="4" class="text-center">
              <lottie
                :options="defaultOptions"
                v-on:animCreated="handleAnimation"
                :height="150"
                :width="150"
              />
            </v-col>
          </v-row>
        </v-container>
      </transition-page>
    </v-main>

    <!-- <v-footer app padless dark v-if="appFrame && !isMobile">
      <v-col class="text-left caption" cols="2">
        <span>
          <i class="fas fa-code-branch"></i>
          v{{ version }}
        </span>
      </v-col>
      <v-col class="text-center caption" cols="8" v-if="!isMobile">
        de
        <i class="fas fa-mug-hot"></i> a <i class="fas fa-code"></i> por:
        <b>
          <a class="white--text" href="http://ocho.life" target="_blank"
            >OCHO App.</a
          >
        </b>
        <span>&copy; Ocho Corporation S.A. de C.V.</span>
      </v-col>
    </v-footer> -->
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <v-snackbar top v-model="refreshRequired">
      La versión del panel administrativo se ha actualizado. Refresca la página
      para disfrutar de nuevas características
      <v-btn color="primary" rounded text @click="refreshPage">
        Refrescar
      </v-btn>
    </v-snackbar>

    <orders-report
      v-if="sellReportUsers.includes(user.type) && ordersReportDialog"
      @success="ordersReportDialog = false"
      @cancel="ordersReportDialog = false"
    />
  </v-app>
</template>

<script>
import TransitionPage from "./components/TransitionPage";
import Background from "./components/Background";
import Lottie from "./components/Lottie";
import * as animationData from "./assets/argos.json";
import mobile from "is-mobile";
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";
// import ordersReport from "./views/sudo/orders-reports/new-report";
import menuRender from "@/components/menu";
import appBarC from "@/components/appBar";
import weather from "@/components/weather";
import moment from "moment";
import Snackbar from "@/components/snackbar";
var businessObserver = null;

import { menuRoleLabels } from "./menu/index";

export default {
  name: "App",
  components: {
    TransitionPage,
    Background,
    lottie: Lottie,
    // ordersReport,
    menuRender,
    appBarC,
    Snackbar,
    weather,
  },

  data: () => ({
    version: process.env.VUE_APP_VERSION,
    anim: null,
    animationSpeed: 1.1,
    defaultOptions: {
      animationData: animationData.default,
      loop: true,
      autoplay: true,
    },
    modalCardPayments: false,
    modalReversals: false,
    ordersReportDialog: false,
    // searchMenu: "",
    cities: [],
    weatherDialog: false,
    user: {
      name: null,
      type: null,
    },
    drawer: null,
    mini: false,
    scheduledOrders: [],
    hideMobileNavigation: true,
    versionListener: null,
    loading: true,
    snackbar: false,
    snackbarText: null,
    refreshRequired: false,
    roleLabels: {},
    sellReportUsers: ["sudo", "admin", "EFCM", "ACC", "AC"],
    ochoLogoSrc:
      "https://firebasestorage.googleapis.com/v0/b/el-ocho-data/o/product-default.jpg?alt=media&token=2936fc1f-b1de-49f8-81dd-8ad132ef52df",
    helpRequests: [],
    baseRoute: {
      sudo: "/management/redemptions",
      admin: "/management/redemptions",
      owner: "/business/dashboard",
      marketing: "/reports",
      affiliateSeller: "/business/redemptions",
      // businessOwner: "/orders",
      // corporateCoupons: "/coupons/general",
      // AF: "/management/redemptions",
      // EFCM: "/management/redemptions",
      // AC: "/management/redemptions",
      // ACC: "/management/redemptions",
      // cashier: "/management/redemptions",
    },
    validRoles: [
      "sudo",
      "admin",
      // "businessOwner",
      // "AF",
      // "EFCM",
      // "AC",
      // "ACC",
      // "corporateCoupons",
      // "cashier",
      "owner",
      "marketing",
      "affiliateSeller",
    ],
    cineUserMenu: [
      {
        icon: "fas fa-users-cog",
        title: "Administración de usuario",
        link: "/cine/user",
        disabled: false,
        roles: ["businessOwner"],
      },
      {
        icon: "fa-solid fa-ticket",
        title: "Tickets Cine",
        link: "/orderstickets",
        disabled: false,
        roles: ["businessOwner"],
      },
      {
        icon: "fa-regular fa-popcorn",
        title: "Refresqueria Cine",
        link: "/orderstheatre",
        disabled: false,
        roles: ["businessOwner"],
      },
    ],
  }),
  computed: {
    ...mapState([
      "sudoBusinessesList",
      "selectedBusiness",
      "selectedCorporative",
      "menu",
      "allowNotifications",
    ]),

    appFrame() {
      return this.$store.state.appFrame;
    },
    navBar() {
      return this.$store.state.navBar;
    },
    isMobile() {
      return mobile();
    },
  },
  mounted() {
    this.$store.commit("setSearch", "");
    this.$vuetify.theme.dark = this.$store.state.darkmode;
    this.roleLabels = menuRoleLabels;

    console.log("Environment", process.env.NODE_ENV);

    fb.auth().onAuthStateChanged((authUser) => {
      if (authUser) {
        this.$binding("user", db.collection(`appUsers`).doc(authUser.uid))
          .then(async (user) => {
            this.loading = true;

            // user.showPageCine = si el Usuario Administra Cine
            // if (user.showPageCine) {
            //   for (let i = 0; i < this.cineUserMenu.length; i++) {
            //     const element = this.cineUserMenu[i];
            //     // if(this.roleLabels.menu.items.)

            //     // console.log("element", element.link);
            //     let exist = this.roleLabels.menu.items.find(
            //       (e) => e.link == element.link
            //     );

            //     if (!exist) {
            //       this.roleLabels.menu.items.splice(0 + 1, 0, element);
            //     }
            //   }
            //   this.addMenu(this.roleLabels.menu.items);
            // } else {
            this.addMenu(this.roleLabels.menu.items);
            // }

            // if (
            //   this.user.type == "corporateCoupons" &&
            //   !this.selectedCorporative
            // ) {
            //   let business = await db
            //     .collection("corporateCoupons")
            //     .doc(user.businessID)
            //     .get();
            //   business = business.data();
            //   business.type = "coupons";
            //   business[".key"] = user.businessID;
            //   console.log(business);
            //   this.addCorporative(business);
            // }

            // this.getHelpRequest();

            // this.getScheduledOrders();

            if (!this.validRoles.includes(user.type) || !this.user.active) {
              this.$store.commit("setUserValidity", false);

              this.snackbarText =
                "Este perfíl de usuario no tiene permitido ingresar a la plataforma";
              this.snackbar = true;
              this.logOut();
            } else {
              if (this.$router.currentRoute.name == "login") {
                this.$router.push({ path: this.baseRoute[this.user.type] });
              } else if (
                this.$router.currentRoute.meta.allowedRoles &&
                !this.$router.currentRoute.meta.allowedRoles.includes(user.type)
              ) {
                this.$router
                  .push({ path: this.baseRoute[this.user.type] })
                  .catch((e) => {
                    console.log(e);
                  });
              }

              let currentPath = this.$router.currentRoute.path;

              if (
                this.selectedBusiness &&
                !currentPath.includes("/business/")
              ) {
                this.addBusiness(null);
              }
            }

            this.loading = false;
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        this.loading = false;

        if (this.$router.history.current.fullPath != "/login")
          this.$router.push({ path: "/login" });
      }
    });

    if (this.isMobile) {
      this.hideMobileNavigation = false;
    }
  },
  watch: {
    user(val) {
      if (val.type) {
        if (!this.validRoles.includes(val.type) || !val.active) {
          this.$store.commit("setUserValidity", false);
          this.snackbarText =
            "Este perfíl de usuario no tiene permitido ingresar a la plataforma";
          this.snackbar = true;
          this.logOut();
        } else {
          this.$store.commit("setUser", val);
        }
      }
    },
  },

  filters: {
    filterRoleName(e) {
      let roles = {
        AF: "Agente Facilitador",
        sudo: "Administrador",
        // businessOwner: "Socio Comercial",
        // ACC: "Agente Cuenta Contable",
        // AC: "Agente Comercial",
        admin: "Administrador",
        // EFCM: "Enlace Facilitador",
        // cashier: "Cajero",
        owner: "Socio Ferretero",
        marketing: "Analista de Marketing",
        affiliateSeller : 'Comercio Afiliado'
      };

      return roles[e] ? roles[e] : "Desconocido";
    },
  },

  methods: {
    ...mapActions([
      "addBusiness",
      "addCorporative",
      "addMenu",
      "showNotifications",
      "updateCity",
    ]),
    handleFunction(item) {
      if (item.flag) {
        this[item.flag] = true;
      } else if (item.function) {
        item.function();
      }
      if (this.isMobile) {
        this.hideMobileNavigation = false;
      }
    },

    hideMenu() {
      this.hideMobileNavigation = !this.hideMobileNavigation;
    },

    refreshPage() {
      window.location = window.location.href + "?eraseCache=true";
    },

    async businessListener(val) {
      if (businessObserver) {
        await businessObserver();
      }

      businessObserver = db
        .collection("businesses")
        .doc(val[".key"])
        .onSnapshot((response) => {
          let business = response.data();
          business[".key"] = response.id;
          this.addBusiness(business);
        });
    },
    logOut() {
      if (this.user) {
        fb.auth()
          .signOut()
          .then(() => {
            this.$store.state.selectedBusiness = "";
            this.addMenu([]);
          })
          .catch(function (error) {});
      }
    },
    handleAnimation(anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },

    toggleDarkmode() {
      let currentMode = this.$vuetify.theme.dark;
      this.$store.commit("setDarkMode", !currentMode);
      this.$vuetify.theme.dark = !currentMode;
    },

    goToHome() {
      this.$router.push({ path: "/" });
    },

    getScheduledOrders() {
      this.$binding(
        "scheduledOrders",
        db
          .collection(`scheduledOrders`)
          .where("progressPlacedAt", ">", moment().startOf("day").toDate())
          .where("progressPlacedAt", "<", moment().endOf("day").toDate())
          .where("state", "==", "placed")
      );
    },

    getHelpRequest() {
      if (["admin", "sudo", "AC", "EFCM", "AF"].includes(this.user.type)) {
        this.$binding(
          "helpRequests",
          db.collection("helpRequests").where("status", "!=", "completed")
        );
      }
    },
  },
  async beforeDestroy() {
    if (this.versionListener) {
      await this.versionListener();
    }
  },
};
</script>

<style src="./assets/css/animate.css"></style>

<style lang="scss">
@import "~@/assets/scss/brands.scss";
@import "~@/assets/scss/fontawesome.scss";
@import "~@/assets/scss/light.scss";
@import "~@/assets/scss/regular.scss";
@import "~@/assets/scss/solid.scss";
@import "@/_responsive.scss";
@import "@/main.scss";

@font-face {
  font-family: "nexa";
  src: url("~@/assets/fonts/nexa_regular-webfont.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "nexa";
  src: url("~@/assets/fonts/nexa_light-webfont.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "nexa";
  src: url("~@/assets/fonts/nexa_bold-webfont.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}

html {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}

body .v-application {
  font-family: "nexa" !important;
  // position: fixed;
  // top:0;
}

.nav-drawer-mobile {
  height: calc(100vh - 56px) !important;
  bottom: 0;
  margin-top: 56px !important;
  position: fixed !important;
}

body .v-application {
  .display-1,
  .display-2,
  .display-3,
  .display-4,
  .headline,
  .title,
  .subtitle-1,
  .subtitle-2,
  .body-1,
  .body-2,
  .caption,
  .overline {
    font-family: "nexa" !important;
  }
}

.v-card__title {
  word-break: normal !important;
}

.cardBox {
  background-color: white;
  border-radius: 10px;
  padding: 8px 4px !important;
  flex-grow: 0 !important;
  width: 100%;
  // min-width: calc(100vw - 24px);
}

.menu-nav-mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
}

.v-dialog {
  border-radius: 10px !important;
}

.v-card {
  border-radius: 10px !important;
  white-space: pre-wrap;
}

.v-snack__wrapper {
  border-radius: 10px !important;
  background-color: rgba(0, 0, 0, 0.9) !important;
}

.v-btn--rounded {
  // border-radius: 10px !important;
  font-weight: bold !important;
  text-transform: unset !important;
}

.disclaimer-text {
  font-size: 14px;
  font-weight: 500;

  span {
    display: inline-block;
    line-height: 13px;
    font-weight: normal;
    font-size: 12px;
  }
}

body
  .v-application
  .v-text-field--rounded
  > .v-input__control
  > .v-input__slot {
  border-radius: 10px !important;
  padding: 0 14px !important;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: #c1d72f;
  box-shadow: none;
  -webkit-box-shadow: none;
  transition: background-color 5000s ease-in-out 0s;
}

.v-data-table tbody tr.v-data-table__expanded__content {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.v-input__append-inner {
  margin-right: 10px;
}

.v-navigation-drawer__border {
  display: none;
}

.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 6px !important;
}

.v-icon.v-icon {
  font-size: 16px !important;
}

.titleBasa svg {
  position: relative;
  height: 32px;
  top: 2px;
  // margin-right: 5px;
  display: inline-block;
}

.v-list-item__avatar {
  // -ms-flex-item-align: center;
  // align-self: center;
  // -webkit-box-pack: start;
  // -ms-flex-pack: start;
  // justify-content: flex-start;
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}

.v-date-picker-table .v-btn {
  border-radius: 28px !important;
  // margin: 10px;
}

// .v-picker--date {
//   padding: 10px 0;
// }

.v-picker__actions.v-card__actions .row.no-gutters {
  padding: 0 10px;
}

// .v-picker__body{
//   // padding: 0 20px;
// }

.v-tooltip__content {
  background-color: rgba(0, 0, 0, 0.9) !important;
  border-radius: 25px !important;
}

.v-data-table-header__icon {
  margin: 0 4px !important;
  color: #c1d72f !important;
}

body
  .v-application
  .v-text-field--rounded
  > .v-input__control
  > .v-input__slot {
  border-radius: 10px !important;
}

.v-autocomplete__content.v-menu__content {
  border-radius: 10px !important;
}

.v-list-item--disabled {
  opacity: 0.3 !important;
}

.v-list-item--disabled .v-avatar.v-list-item__avatar img {
  opacity: 0.8 !important;
  filter: grayscale(100%);
}

.theme--light.v-list {
  background: #e6e6e6 !important;
}

.theme--dark.v-list {
  background: #111111 !important;
}

.v-snack__content {
  justify-content: center !important;
  display: flex;
}

.submenu-items {
  width: 100%;
  // margin-top: 20%;
}

.logo-menu {
  border-radius: 30px;
  width: 30px;
  height: 30px;
  margin-left: -10px;
  margin-right: 10px;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background-color: #f2f2f2;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  background: #3a3a3a79 !important;
  border-radius: 2px;
}

.v-list--rounded .v-list-item,
.v-list--rounded .v-list-item::before,
.v-list--rounded .v-list-item > .v-ripple__container {
  // border-radius: 32px !important;
  display: flex;
  justify-content: center;
}

.control-input {
  border: 1px solid rgba(90, 89, 89, 0.315);
  border-radius: 10px;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  color: black;
}

.control-input-number {
  border: 1px solid rgba(90, 89, 89, 0.315);
  border-radius: 10px;
  width: 100% !important;
  padding: 10px;
  margin-top: 10px;
}

.control-input:focus {
  outline: none !important;
  border: 1px solid #f06f3d;
  box-shadow: 0 0 5px #f06f3d;
}

.ant-btn-icon-only.ant-btn-sm {
  width: 40px !important;
  height: 40px !important;
  border-radius: 40px !important;
  color: rgb(255, 255, 255);
}

.ant-checkbox-checked::after {
  border: 0 !important;
}

.ant-btn-primary {
  background-color: #f06f3d !important;
  border-color: #f06f3d !important;
}

.ant-btn-primary:disabled {
  border-color: #dddddd !important;
  background: #dddddd !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #f06f3d !important;
  border-color: #f06f3d !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #f06f3d !important;
}

.ant-transfer-list-content-item:not(
    .ant-transfer-list-content-item-disabled
  ):hover {
  background-color: #f0703d4b !important;
  border-radius: 50px;
}

.sticky {
  position: fixed !important;
  top: 65px !important;
  z-index: 2 !important;
  transition: visibility 0s, opacity 0.2s linear;
}

.v-btn .v-btn__content {
  text-transform: initial !important;
}

.screen-width {
  width: 80% !important;
}

.main-container {
  width: 100%;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  .v-card__text {
    padding: 0 !important;
  }

  .container {
    padding: 0 !important;
  }

  .screen-width {
    width: 100% !important;
  }
}

textarea {
  padding-left: 20px !important;
}

.save-btn {
  border-radius: 10px !important;
}

.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  border-radius: 10px !important;
}

.v-list-item--link {
  border-radius: 50px;
  // background: black;
}

.Snack {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

label,
h3,
p {
  color: black;
}

.leaflet-control-geosearch form input {
  padding: 17px !important;
  font-weight: bold;
}

.leaflet-control-geosearch a.reset {
  color: #e74c3c !important;
  font-weight: bold;
  font-size: 15px;
  margin-top: 5px;
}

.v-text-field--filled > .v-input__control > .v-input__slot,
.v-text-field--full-width > .v-input__control > .v-input__slot,
.v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 45px !important;
}

.v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--box
  .v-select__selections,
.v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed
  .v-select__selections {
  min-height: 50px !important;
}

.business-active {
  margin-left: -10px;
}

.new-help-request {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    color: #f06a25;
  }

  50% {
    color: #ffffff;
  }

  100% {
    color: #f06a25;
  }
}

.bigger-font {
  font-size: 1rem;
}

.nowrap {
  white-space: nowrap !important;
}

.first-child {
  margin-left: 20px;
}

.v-list-group--active {
  background-color: #0f0613 !important; //#353434ba !important;
}

.v-list-group--active .v-list-group__items .v-list-item--active {
  color: #000000 !important;
  // background: #343434 !important;
  // border-left: 2px solid rgba(106, 106, 106, 0.12);
  border-radius: 0;

  div {
    color: #c1d72f;
    font-weight: bold;
    // font-size: 15px !important;
  }
}

.v-list-group--active .v-list-group__items .v-list-item:hover {
  color: #000000 !important;
  background: #343434 !important;
  // border-left: 2px solid rgba(255, 255, 255, 0.12);
  border-radius: 0;

  div {
    color: white;
  }
}

.v-list-group--active {
  padding-bottom: 10px !important;
}

.leaflet-control-geocoder {
  display: none;
}

.v-application--is-ltr
  .v-list-group--sub-group
  .v-list-item__action:first-child,
.v-application--is-ltr
  .v-list-group--sub-group
  .v-list-item__avatar:first-child,
.v-application--is-ltr .v-list-group--sub-group .v-list-item__icon:first-child {
  display: none !important;
}

.main-notification {
  font-size: 18px !important;
  padding: 10px;
  margin-bottom: 10px;
  color: #fff;
  background: #474747da !important;
  border-left: 5px solid #c1d72f !important;
}

.notification {
  display: grid;
  grid-template-columns: 15% 70% 15%;
  justify-content: center;
  align-items: center;
}

.hide-notification {
  display: flex;
  justify-content: end;

  p {
    color: #c1d72f;
    cursor: pointer;
    margin: 0;
    padding: 0;
    font-weight: bold;
  }
}

.category-color {
  -webkit-appearance: none;
  width: 100%;
  margin-top: 10px;
  border-radius: 50px;
  height: 45px !important;
  cursor: pointer;
}

.v-btn {
  border-radius: 10px !important;
}

.v-list-group--active .v-list-group__items .v-list-item:hover {
  color: #000000 !important;
  background: #36283d !important;
  border-radius: 0;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}

.v-dialog {
  margin: 0 !important;
}

// button {
.primary {
  color: black !important;

  .v-btn__content {
    color: black !important;
  }
}

// }
</style>
